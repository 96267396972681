import { masker, TOKENS } from '@/utils/mask/mask';

// Create an event
function createEvent(name) {
    return new Event(name, { bubbles: true, cancelable: true });
}

function mounted(el, binding) {
    let config = binding.value;

    if (Array.isArray(config) || typeof config === 'string') {
        config = {
            mask: config,
            tokens: TOKENS,
        };
    }

    if (el.tagName.toUpperCase() !== 'INPUT') {
        const inputs = el.getElementsByTagName('input');
        if (inputs.length !== 1) {
            throw new Error(`v-mask directive requires 1 input, found ${inputs.length}`);
        } else {
            // Use the first input element
            el = inputs[0];
        }
    }

    const handleInput = evt => {
        // Avoid infinite loop
        if (!evt.isTrusted) return;

        let position = el.selectionEnd;
        const digit = el.value[position - 1];

        el.value = masker(el.value, config.mask, true, config.tokens);

        while (position < el.value.length && el.value.charAt(position - 1) !== digit) {
            position++;
        }

        if (el === document.activeElement) {
            el.setSelectionRange(position, position);
            setTimeout(() => {
                el.setSelectionRange(position, position);
            }, 0);
        }

        el.dispatchEvent(createEvent('input'));
    };

    el.addEventListener('input', handleInput);

    const newDisplay = masker(el.value, config.mask, true, config.tokens);
    if (newDisplay !== el.value) {
        el.value = newDisplay;
        el.dispatchEvent(createEvent('input'));
    }

    // Save a reference to the handler for use in updated and unmounted hooks
    el._maskInputHandler = handleInput;
}

// Export the directive for Vue 3
export default {
    mounted,

    updated(el, binding) {
        // Update the mask configuration if the value has changed
        if (binding.value !== binding.oldValue) {
            el.removeEventListener('input', el._maskInputHandler);
            // Apply the new handler
            mounted(el, binding);
        }
    },

    unmounted(el) {
        el.removeEventListener('input', el._maskInputHandler);
    },
};

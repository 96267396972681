import { SECTIONS } from './sections';

export default async vendor => {
    return vendor
        .filter(route => {
            return !route.meta || !route.meta.customerId ? false : route;
        })
        .map(route => {
            return {
                id: 'vendor.vendor_edit',
                section: SECTIONS.VENDORS,
                title: route.searchable.name,
                route: 'vendor.vendor_edit',
                routeParams: {
                    id: route.meta.objectId,
                },
                resultId: route.meta.objectId,
            };
        });
};

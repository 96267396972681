import { computed } from 'vue';
import moment from 'moment-timezone';
import { useStore } from 'vuex';
import { checkFormatDate } from '@/utils/Date';

export const useCommunity = () => {
    const store = useStore();
    const community = computed(() => store.getters['auth/community']);
    const timezone = computed(() => community.value?.timezoneId ?? null);

    /**
     * Prepare moment object in current time with respect to community timezone
     * @returns {moment.Moment}
     */
    const getCurrentDate = () => {
        return moment().tz(timezone.value);
    };

    /**
     * Prepare moment object with respect to community timezone
     * @param date ISO8601 string
     * @param keepLocalTime pass true to have only the timezone (and offset) updated, keeping the local time same. Useful for date strings without time (e.g. 2022-10-23)
     * @returns {moment.Moment}
     */
    const tz = (date, options = {}) => {
        const { keepLocalTime = false, inputFormat } = options;
        const preparedDate = checkFormatDate(date);

        if (!timezone.value) {
            return moment(preparedDate, inputFormat);
        }

        return moment(preparedDate, inputFormat).tz(timezone.value, keepLocalTime);
    };

    /**
     * Prepare datetime string with respect to community timezone
     *
     * @param date ISO8601 string
     * @param format moment.js formatting rule string
     * @param keepLocalTime pass true to have only the timezone (and offset) updated, keeping the local time same. Useful for date strings without time (e.g. 2022-10-23)
     * @returns {string}
     */
    const getFormatDate = (date, options = {}) => {
        if (!date) {
            return;
        }

        const { keepLocalTime, inputFormat, outputFormat = 'MM/DD/YYYY hh:mm a' } = options;
        return tz(date, { keepLocalTime, inputFormat }).format(outputFormat);
    };

    return {
        community,
        getCurrentDate,
        tz,
        timezone,
        getFormatDate,
    };
};

<template>
    <div class="">
        <FinalForm
            v-if="initialValues"
            :submit="handleSubmit"
            :initial-values="initialValues"
        >
            <template #default="props">
                <form @submit="props.handleSubmit">
                    <div class="form-row">
                        <ToggleField
                            name="moveOutPhysicalStatusAutoUpdate"
                            label="move out physical status auto update"
                            :input-label="props.values.moveOutPhysicalStatusAutoUpdate ? 'enabled' : 'disabled'"
                            :disabled="$boolAttr(!editable)"
                        />
                    </div>
                    <template v-if="props.values.moveOutPhysicalStatusAutoUpdate">
                        <div class="form-row">
                            <SelectInput
                                name="moveOutPhysicalStatusLabelId"
                                label="move out physical status label id"
                                :options="statusLabels"
                                option-key="id"
                                option-label="name"
                                :edit-mode="editable"
                                :validate="required"
                                :disabled="$boolAttr(!props.values.moveOutPhysicalStatusAutoUpdate)"
                            />
                        </div>
                        <div class="form-row">
                            <CounterField
                                name="moveOutPhysicalStatusReservationPeriod"
                                label="move out physical status reservation period"
                                :min="1"
                                :max="365"
                                :edit-mode="editable"
                                :disabled="$boolAttr(!props.values.moveOutPhysicalStatusAutoUpdate)"
                                :validate="minValue(1)"
                            />
                        </div>
                        <div class="form-row">
                            <div>
                                <CounterField
                                    name="unitPreparationLeadTime"
                                    label="days for unit availability"
                                    :min="1"
                                    :max="365"
                                    :hide-hint="true"
                                    :edit-mode="editable"
                                    :disabled="$boolAttr(!props.values.moveOutPhysicalStatusAutoUpdate)"
                                    :validate="minValue(1)"
                                />
                                <span class="text-2sm">Apply a number of days for Unit Availability to calculate availability date at lease end.</span>
                            </div>
                        </div>
                    </template>
                    <div
                        v-if="editable"
                        class="form-row"
                    >
                        <button
                            class="btn-primary btn-solid"
                            type="submit"
                        >
                            save
                        </button>
                    </div>
                </form>
            </template>
        </FinalForm>

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import Loader from '@/components/ui/Loader';
import { useAlertStore } from '@/stores/alert';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import CounterField from '@/components/form/CounterField';
import ToggleField from '@/components/form/ToggleField';
import SelectInput from '@/components/form/SelectInput';

export default {
    components: { SelectInput, ToggleField, CounterField, Loader, FinalForm },

    mixins: [ValidatorMixin],

    props: {
        editable: {
            type: Boolean,
            default: true,
        },
    },

    setup() {
        const { notifySuccess, notifyError } = useAlertStore();
        return { notifySuccess, notifyError };
    },

    data() {
        return {
            loading: false,
            initialValues: null,
            statusLabels: [],
        };
    },

    mounted() {
        this.loading = true;

        Promise.all([
            this.$cbrDataProvider.getAll('unitStatusSettings').then(response => {
                this.initialValues = {
                    moveOutPhysicalStatusAutoUpdate: response.moveOutPhysicalStatusAutoUpdate,
                    moveOutPhysicalStatusLabelId: response?.moveOutPhysicalStatusLabel?.id || null,
                    moveOutPhysicalStatusReservationPeriod: response?.moveOutPhysicalStatusReservationPeriod || 1,
                    unitPreparationLeadTime: response?.unitPreparationLeadTime || null,
                };
            }),
            this.$cbrDataProvider.getMoveOut('unitStatuses').then(categories => {
                this.statusLabels = categories[0].statuses.reduce((statusLabels, status) => {
                    return [...statusLabels, ...status.labels];
                }, []);
            }),
        ])
            .catch(error => {
                this.notifyError(error);
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        handleSubmit(values) {
            this.loading = true;

            this.$cbrDataProvider
                .update('unitStatusSettings', {
                    data: values.moveOutPhysicalStatusAutoUpdate
                        ? values
                        : { moveOutPhysicalStatusAutoUpdate: values.moveOutPhysicalStatusAutoUpdate },
                })
                .then(() => {
                    this.notifySuccess('settings successfully saved');
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const uiVersions = {
    V2: 'v2',
    V3: 'v3',
};

export const useUIStore = defineStore(
    'ui',
    () => {
        const ui = ref(uiVersions.V2);

        const v3 = computed(() => ui.value === uiVersions.V3);

        function setUIVersion(version) {
            ui.value = version;
        }

        return {
            ui,
            v3,
            setUIVersion,
        };
    },
    { persist: true }
);

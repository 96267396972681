import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useCommunity } from '@/composables/useCommunity';
import CmsDataProvider from '@/api/cms';
import { useAlertStore } from '@/stores/alert';

export function useWebsitesMenuItem() {
    const store = useStore();
    const { notifyError } = useAlertStore();

    const hasCMSPermission = computed(() => store.getters['cms/hasPermission']);
    const { community } = useCommunity();
    const websiteIdbyCommunity = ref(null);

    const hasWebsite = computed(() => {
        if (community.value?.id) {
            hasWebsiteV2();
            return !!websiteIdbyCommunity.value;
        } else {
            return false;
        }
    });

    const hasWebsiteV2 = () => {
        CmsDataProvider.adapter
            .get('websiteIdbyCommunity', { communityId: community.value?.id })
            .then(response => {
                if (response.status === 'ok') {
                    websiteIdbyCommunity.value = response.message;
                } else {
                    if (response.status === 'empty') {
                        websiteIdbyCommunity.value = null;
                    }
                    if (response.status === 'error') {
                        notifyError('There is an error in the API: websiteIdbyCommunity.');
                        websiteIdbyCommunity.value = null;
                    }
                }
            })
            .catch(() => {
                notifyError('There was an error getting the website ');
                websiteIdbyCommunity.value = null;
            });
    };

    const webSiteSettingsRoute = 'websites.settings';
    const webSiteShowRoute = 'websites.show';

    const websitesRoutesParams = computed(() => ({
        [webSiteSettingsRoute]: { id: websiteIdbyCommunity.value },
        [webSiteShowRoute]: { id: websiteIdbyCommunity.value },
    }));

    const websitesMenuItems = computed(() => ({
        items: [
            {
                icon: 'globe',
                id: '__websites',
                label: 'Websites',
                permissionChecker: hasCMSPermission.value,
                children: [
                    {
                        permissionChecker: !hasWebsite.value,
                        id: 'websites.select-property',
                        label: 'Add new website',
                    },
                    {
                        permissionChecker: hasWebsite.value,
                        id: webSiteShowRoute,
                        label: 'Manage website',
                    },
                    {
                        permissionChecker: hasWebsite.value,
                        id: webSiteSettingsRoute,
                        label: 'Website settings',
                    },
                ],
            },
        ],
    }));

    return { websitesMenuItems, websitesRoutesParams };
}

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useIotPermissionsStore } from '@/stores/iot/permissions';

export function useIotMenuItem() {
    const store = useStore();
    const { hasPermissions: hasIotPermissions } = useIotPermissionsStore();

    const hasAnyIotAccess = computed(() =>
        hasIotPermissions([
            'TEMPORARY_USER_ACCESS_READ_LIST',
            'LOCK_READ_LIST',
            'CUSTOM_USER_GROUP_READ_LIST',
            'USER_READ_LIST',
            'RESIDENT_READ_LIST',
            'FIRE_KEY_VIEW_FIRE_KEY_TILE',
            'SMART_POLICY_READ',
            'LOCATION_READ_LIST',
            'GROUP_USER_ACCESS_READ_LIST',
            'THERMOSTAT_READ_LIST',
            'LOCK_READ_LIST',
            'DELAY_LINE_READ',
            'LEASE_READ_LIST',
            'RESIDENT_READ_LIST',
            'RESIDENT_READ_RESIDENT_EVENT_QUEUE',
            'SENSOR_INDUSTRIES_READ',
            'USER_READ_LIST',
            'TEMPORARY_USER_ACCESS_READ_LIST',
        ])
    );

    const userRole = computed(() => store.getters['auth/userRole']);

    const isAdmin = computed(() => ['SUPER_ADMIN', 'CUSTOMER_ADMIN'].includes(userRole.value));

    const iotMenuItems = computed(() => ({
        items: [
            {
                icon: 'smart-home',
                id: '__iot',
                label: 'IOT',
                permissionChecker: hasAnyIotAccess.value,
                children: [
                    {
                        permissionChecker: hasIotPermissions([
                            'TEMPORARY_USER_ACCESS_READ_LIST',
                            'LOCK_READ_LIST',
                            'CUSTOM_USER_GROUP_READ_LIST',
                            'USER_READ_LIST',
                            'RESIDENT_READ_LIST',
                            'FIRE_KEY_VIEW_FIRE_KEY_TILE',
                        ]),
                        label: 'Access',
                        id: 'iot.access.index',
                    },

                    {
                        permissionChecker: hasIotPermissions([
                            'SMART_POLICY_READ_LIST',
                            'AUTO_OPEN_READ_LIST',
                            'LOCK_CREDENTIALS_SCHEDULE_READ_LIST',
                        ]),
                        label: 'Automation',
                        id: 'iot.automation',
                    },

                    {
                        permissionChecker: hasIotPermissions(['LOCATION_READ_LIST', 'GROUP_USER_ACCESS_READ_LIST']),
                        label: 'Community',
                        id: 'iot.community',
                    },

                    {
                        permissionChecker: hasIotPermissions(['THERMOSTAT_READ_LIST', 'LOCK_READ_LIST']),
                        label: 'Devices',
                        id: 'iot.devices',
                    },

                    {
                        permissionChecker: hasIotPermissions('DELAY_LINE_READ'),
                        label: 'Integration queue',
                        id: 'iot.integration_queue.index',
                    },

                    {
                        permissionChecker: hasIotPermissions('LEASE_READ_LIST'),
                        label: 'Leases',
                        id: 'iot.leases.index',
                    },

                    {
                        permissionChecker: hasIotPermissions('RESIDENT_READ_LIST'),
                        label: 'Residents',
                        id: 'iot.residents.index',
                    },

                    {
                        permissionChecker: hasIotPermissions('RESIDENT_READ_RESIDENT_EVENT_QUEUE'),
                        label: 'Resident queue',
                        id: 'iot.residents-queue.index',
                    },

                    {
                        permissionChecker: hasIotPermissions('SENSOR_INDUSTRIES_READ'),
                        label: 'Sensor industries',
                        id: 'iot.si-dash.index',
                    },

                    {
                        permissionChecker: hasIotPermissions('USER_READ_LIST'),
                        label: 'Staff',
                        id: 'iot.staff.index',
                    },

                    {
                        permissionChecker: hasIotPermissions('USER_READ_LIST'),
                        label: 'Vendors',
                        id: 'iot.vendors.index',
                    },

                    {
                        permissionChecker: isAdmin.value || hasIotPermissions('TEMPORARY_USER_ACCESS_READ_LIST'),
                        label: 'IOT settings',
                        id: 'iot.settings',
                    },
                ],
            },
        ],
    }));

    return { iotMenuItems };
}

<template>
    <div
        v-if="!lazy"
        v-show="isActive"
    >
        <!-- @slot tab content -->
        <slot :is-active="isActive" />
    </div>
    <div v-else-if="isActive">
        <!-- @slot tab content -->
        <slot :is-active="isActive" />
    </div>
</template>

<script>
export default {
    inject: ['registerTab', 'unregisterTab'],

    props: {
        /**
         * Tab title
         */
        title: {
            type: String,
            required: true,
        },

        /**
         * If the tab should be disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Not hidden using css, useful to avoid loading resources before the tab is selected.
         */
        lazy: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['appear'],

    data() {
        return {
            isActive: false,
        };
    },

    watch: {
        isActive(val) {
            if (val) {
                this.$emit('appear');
            }
        },
    },

    mounted() {
        if (typeof this.registerTab === 'function') {
            this.registerTab(this);
        }
    },

    beforeUnmount() {
        if (typeof this.unregisterTab === 'function') {
            this.unregisterTab(this);
        }
    },
};
</script>

<template>
    <div class="h-full overflow-hidden">
        <loader :loading="loading" :backdrop="true" />

        <file-manager-title>
            <span class="text-highlight-textSmall">edit image</span>
        </file-manager-title>

        <div class="flex h-full mt-16">
            <image-editor
                :image="file.base64"
                :aspect-ratio="manager.uploader.cropperOptions.aspectRatio"
                :crop-box-resizable="manager.uploader.cropperOptions.cropBoxResizable"
                :zoom-on-wheel="manager.uploader.cropperOptions.zoomOnWheel"
                @image-cropped="cropImage"
                v-if="!loading && file && file.base64"
            >
            </image-editor>
        </div>

        <div class="absolute bottom-0 left-0 w-full px-8 py-6 flex justify-between bg-gray-200">
            <button
                class="btn-primary btn-transparent"
                :disabled="$boolAttr(loading)"
                @click="close"
            >
                cancel
            </button>
            <button
                class="btn-primary btn-solid"
                @click="edit"
                :disabled="$boolAttr(loading)"
            >
                save
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import ImageEditor from "@/components/ui/filemanager/ImageEditor";
import FileManagerTitle from "@/components/ui/filemanager/FileManagerTitle";

export default {
    mixins: [ NotifyMixin ],
    props: {
        managerId: {
            type: String,
            required: true
        },
        fileUuid: {
            type: String,
            required: true,
        }
    },
    emits: [
        'close',
        'uploaded-to-s3',
    ],
    components: { Loader, FileManagerTitle, ImageEditor },
    data: () => {
        return {
            loading: true,
            manager: {},
            file: {
                base64: null
            },
        }
    },
    computed: {
        ...mapGetters({
            getManagerById: 'files/getManagerById'
        }),
    },
    methods: {
        ...mapActions({
            addFilesToLibrary: 'files/addFilesToLibrary',
            addFilesToManagerSelectedFiles: 'files/addFilesToManagerSelectedFiles',
            removeFileFromManagerSelectedFiles: 'files/removeFileFromManagerSelectedFiles',
            addFilesToManagerLibrary: 'files/addFilesToManagerLibrary',
        }),
        cropImage(payload) {
            this.file.base64 = payload.image;
        },
        edit() {
            this.loading = true;

            this.$filemanagerDataProvider.update('updateFile', {
                id: this.fileUuid,
                data: { file: this.file.base64 }
            }).then( (response) => {
                let files = response.data;
                let payload = { managerId: this.manager.managerId, files: files };

                // create file api and get file api are not consistent
                // the first returns file_classification and the second returns file_type
                files.forEach( (file) => {
                    if(Object.hasOwn(file, 'file_classification')) {
                        file.file_type = file.file_classification;
                    }
                });

                this.addFilesToLibrary(files);

                this.addFilesToManagerLibrary(payload);

                this.removeFileFromManagerSelectedFiles({ managerId: this.manager.managerId, file: this.file })
                this.addFilesToManagerSelectedFiles(payload);

                files.forEach( _file => this.$emit('uploaded-to-s3', _file));
            }).catch(() => {
                this.notifyError('api error updating file.');
            })
            .finally( () => {
                this.loading = false;
                this.close();
            });
        },
        close() {
            this.$emit('close');
        }
    },
    created() {
        this.manager = this.getManagerById(this.managerId);
    },
    mounted() {
        this.loading = true;
        this.$filemanagerDataProvider.get("fileInfo", { fileId: this.fileUuid })
            .then( (response) => {
                if(!response.base64) {
                    this.notifyError('api did not return base64 image.');
                }
                this.file = response;
            }).catch( () => {
                this.notifyError('api error fetching file.');
            })
            .finally( () => {
                this.loading = false;
            });
    }
}
</script>

<template>
    <QxtSideNav
        :community="{
            name: community?.name,
        }"
        :model-value="routeName"
        :menu="menu"
        @update:model-value="updateActiveNavItem"
    />
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useDevToolsMenuItem } from '@/components/layout/v3/navigation/menu_items/devTools';
import { useSearchEngine } from '@/components/layout/v3/navigation/menu_items/searchEngine';
import { useEmailBotItem } from '@/components/layout/v3/navigation/menu_items/emailBot';
import { useIotMenuItem } from '@/components/layout/v3/navigation/menu_items/iot';
import { useVendorsMenuItem } from '@/components/layout/v3/navigation/menu_items/vendors';
import { useAccountsReceivableMenuItem } from '@/components/layout/v3/navigation/menu_items/accountsReceivable';
import { useAccountsPayableMenuItem } from '@/components/layout/v3/navigation/menu_items/accountsPayable';
import { useGeneralLedgerMenuItem } from '@/components/layout/v3/navigation/menu_items/generalLedger';
import { usePortfolioSetupMenuItem } from '@/components/layout/v3/navigation/menu_items/portfolioSetup';
import { useQuextSetupMenuItem } from '@/components/layout/v3/navigation/menu_items/quextSetup';
import { useResidentLifecycleMenuItem } from '@/components/layout/v3/navigation/menu_items/residentLifecycle';
import { useCommunitySettingsMenuItem } from '@/components/layout/v3/navigation/menu_items/communitySettings';
import { usePhysicalPropertyLifecycleMenuItem } from '@/components/layout/v3/navigation/menu_items/physicalPropertyLifecycle';
import { useDigitalHumanAiMenuItem } from '@/components/layout/v3/navigation/menu_items/digitalHumanAi';
import { useDigitalHumanMenuItem } from '@/components/layout/v3/navigation/menu_items/digitalHuman';
import { useMarketingMenuItem } from '@/components/layout/v3/navigation/menu_items/marketing';
import { useWebsitesMenuItem } from '@/components/layout/v3/navigation/menu_items/websites';
import { useHomeMenuItem } from '@/components/layout/v3/navigation/menu_items/home';
import { QxtSideNav } from '@asmartment/qui-base';
import { useAlertStore } from '@/stores/alert';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners';
import IntegrationsPartnersDataProvider from '@/api/integration_partner';
import { useRouter, useRoute } from 'vue-router';
import { useCommunity } from '@/composables/useCommunity';

const router = useRouter();
const route = useRoute();
const { community } = useCommunity();
const store = useStore();
const ipsStore = useIntegrationPartnersStore();
const { notifyError } = useAlertStore();

const clearWebsiteInformation = () => store.dispatch('website_info/clearWebsiteInformation');

const filterMenuItems = menuItems => {
    return menuItems.reduce((filtered, item) => {
        if (!Object.hasOwn(item, 'permissionChecker') || item.permissionChecker === true) {
            const filteredChildren = item.children
                ? item.children.reduce((childFiltered, child) => {
                    if (!Object.hasOwn(child, 'permissionChecker') || child.permissionChecker === true) return [...childFiltered, child];
                    return childFiltered;
                }, [])
                : undefined;

            if (!filteredChildren || filteredChildren.length > 0) {
                filtered.push({
                    ...item,
                    children: filteredChildren,
                });
            }
        }

        return filtered;
    }, []);
};

const { devToolsMenuItems } = useDevToolsMenuItem();
const { searchEngineMenuItems } = useSearchEngine();
const { emailBotItems } = useEmailBotItem();
const { iotMenuItems } = useIotMenuItem();
const { vendorsMenuItems } = useVendorsMenuItem();
const { accountsReceivableMenuItems } = useAccountsReceivableMenuItem();
const { accountsPayableMenuItems } = useAccountsPayableMenuItem();
const { generalLedgerMenuItems } = useGeneralLedgerMenuItem();
const { portfolioSetupMenuItems, portfolioSetupRoutesParams } = usePortfolioSetupMenuItem();
const { quextSetupMenuItems } = useQuextSetupMenuItem();
const { residentLifecycleMenuItems } = useResidentLifecycleMenuItem();
const { communitySettingsMenuItems } = useCommunitySettingsMenuItem();
const { physicalPropertyLifecycleMenuItems } = usePhysicalPropertyLifecycleMenuItem();
const { digitalHumanAiMenuItems } = useDigitalHumanAiMenuItem();
const { digitalHumanMenuItems } = useDigitalHumanMenuItem();
const { marketingMenuItems } = useMarketingMenuItem();
const { websitesMenuItems, websitesRoutesParams } = useWebsitesMenuItem();
const { homeMenuItems } = useHomeMenuItem();

const routesParams = computed(() => ({
    ...portfolioSetupRoutesParams.value,
    ...websitesRoutesParams.value,
}));

const routeName = computed(() => route.name);

const updateActiveNavItem = navItemId => {
    if (!navItemId.startsWith('__')) {
        router.push({ name: navItemId, params: { ...(routesParams.value[navItemId] || {}) } });
    }
};

const menu = computed(() =>
    [
        homeMenuItems.value,
        {
            items: [
                ...websitesMenuItems.value.items,
                ...marketingMenuItems.value.items,
                ...digitalHumanMenuItems.value.items,
                ...digitalHumanAiMenuItems.value.items,
                ...physicalPropertyLifecycleMenuItems.value.items,
                ...communitySettingsMenuItems.value.items,
                ...residentLifecycleMenuItems.value.items,
                ...quextSetupMenuItems.value.items,
                ...portfolioSetupMenuItems.value.items,
                ...generalLedgerMenuItems.value.items,
                ...accountsPayableMenuItems.value.items,
                ...accountsReceivableMenuItems.value.items,
                ...vendorsMenuItems.value.items,
                ...iotMenuItems.value.items,
                ...emailBotItems.value.items,
                ...searchEngineMenuItems.value.items,
                ...devToolsMenuItems.value.items,
            ],
        },
    ].reduce((filteredSections, section) => {
        const filteredItems = filterMenuItems(section.items);

        if (filteredItems.length > 0) {
            filteredSections.push({
                ...section,
                items: filteredItems,
            });
        }

        return filteredSections;
    }, [])
);

onMounted(() => {
    clearWebsiteInformation();
    IntegrationsPartnersDataProvider.adapter
        .get('permissions')
        .then(ipsPermissions => {
            ipsStore.setIpsV2Permissions(ipsPermissions);
        })
        .catch(error => notifyError(error));
});
</script>

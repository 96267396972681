import { computed, ref } from 'vue';
import { useAccountingStore } from '@/stores/accounting';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';
import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';

export function useAccountsReceivableMenuItem() {
    const acctStore = useAccountingStore();

    const permissions = ref({
        journalEntriesTile: USER_PERMISSIONS.ACCT_VIEW_JOURNAL_ENTRIES_LIST,
        glAccountsTile: USER_PERMISSIONS.ACCT_VIEW_GL_ACCOUNTS_LIST,
        bankReconciliation: USER_PERMISSIONS.ACCT_VIEW_RECONCILE,
        bankAccountTile: USER_PERMISSIONS.ACCT_VIEW_BANK_ACCOUNTS_LIST,
        generalLedgerSettings: USER_PERMISSIONS.ACCT_VIEW_GL_SETTINGS,
        accountsReceivableSettings: USER_PERMISSIONS.ACCT_VIEW_AR_SETTINGS,
        depositsTile: USER_PERMISSIONS.ACCT_VIEW_DEPOSIT_LIST,
        chargesTile: USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST,
        invoicesTile: USER_PERMISSIONS.ACCT_VIEW_INVOICES,
        paymentsTile: USER_PERMISSIONS.ACCT_VIEW_PAYMENTS,
        applicationAndLeaseTile: LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT,
        processMoveInsTile: LEASING_PERMISSIONS.LEASE_MOVE_IN,
        renewalOffersTile: LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL,
        transferResidentTile: LEASING_PERMISSIONS.LEASE_TRANSFER_RESIDENTS,
        residentDetailsTileReadOnly: LEASING_PERMISSIONS.LEASE_RESIDENT_DETAILS_READ_ONLY,
        residentDetailsTileWithAccess: LEASING_PERMISSIONS.LEASE_RESIDENT_DETAILS_ACCESS,
        monthToMonthTile: LEASING_PERMISSIONS.LEASE_MONTH_TO_MONTH_MANAGEMENT,
        moveOutResidentsTile: LEASING_PERMISSIONS.LEASE_MOVE_OUT,
        noticeToVacantTile: LEASING_PERMISSIONS.LEASE_NTV,
    });

    const hasAccessToAccountsReceivableSection = computed(() => {
        return acctStore.hasPermission(permissions.value.chargesTile) || acctStore.hasPermission(permissions.value.depositsTile);
    });

    const accountsReceivableMenuItems = computed(() => ({
        items: [
            {
                icon: 'pay-plus',
                permissionChecker: hasAccessToAccountsReceivableSection.value,
                label: 'Accounts receivable',
                id: '__accounts_receivable',
                children: [
                    {
                        permissionChecker: acctStore.hasPermission(permissions.value.depositsTile),
                        label: 'Deposits',
                        id: 'accounts_receivable.deposits',
                    },
                    {
                        permissionChecker: acctStore.hasPermission(permissions.value.chargesTile),
                        label: 'Charges',
                        id: 'accounts_receivable.charges',
                    },
                    {
                        permissionChecker: acctStore.hasPermission(permissions.value.chargesTile),
                        label: 'Credits',
                        id: 'accounts_receivable.credits',
                    },
                ],
            },
        ],
    }));

    return { accountsReceivableMenuItems };
}

import { SECTIONS } from './sections';
function filterOutIncompleteRoutes(route) {
    return route.meta && route.meta.customerId;
}
function filterApplicants(person) {
    return person.occupancyType !== 'applicant';
}
function formatUnitResidents(resident) {
    return {
        ...resident,
        name: `${resident.firstname} ${resident.lastname} (resident)`,
        route: 'manage_residents.resident-details',
        routeParams: {
            id: resident.occupancyid,
            leaseEndDate: new Date().toISOString(),
        },
    };
}
function formatSpaceResult(route) {
    switch (route.meta.type) {
        case 'unittype':
            return {
                path: 'cbr.unittypes.details',
                title: 'unit type',
                objectId: {
                    typeId: route.meta.objectId,
                },
                resultData: route,
            };
        case 'floorplan':
            return {
                path: 'cbr.unittypes.details',
                title: 'unit type',
                objectId: {
                    typeId: route.meta.objectId,
                },
                resultData: route,
            };
        case 'space':
            return route.meta.parentSpaceId === ''
                ? {
                    path: 'cbr.living.communityStructure.detail',
                    title: 'building',
                    objectId: {
                        buildingId: route.meta.objectId,
                    },
                    resultData: route,
                }
                : {
                    path: 'cbr.living.units.details',
                    title: 'unit',
                    objectId: {
                        unitId: route.meta.objectId,
                    },
                    resultData: route,
                };
        default:
            break;
    }
}
export default async locations => {
    return locations.filter(filterOutIncompleteRoutes).map(route => {
        const spaceResultPath = formatSpaceResult(route);
        return {
            id: spaceResultPath.path,
            section: SECTIONS.LOCATIONS,
            title: `${route.searchable.name} (${spaceResultPath.title})`,
            route: spaceResultPath.path,
            routeParams: spaceResultPath.objectId,
            resultId: spaceResultPath.resultData.meta.objectId,
            dependentResult: {
                type: 'residents',
                results: route.meta.persons ? route.meta.persons.filter(filterApplicants).slice(0, 5).map(formatUnitResidents) : null,
            },
        };
    });
};

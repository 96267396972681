<template>
    <FinalField
        :name="name"
        :validate="validate"
    >
        <template #default="props">
            <div class="form-col">
                <FieldLabel
                    v-if="label || !!$slots['label']"
                    :name="name"
                    :tooltip="tooltip"
                    :tooltip-vertical-position="tooltipVerticalPosition"
                >
                    <slot name="label">
                        {{ label }}
                    </slot>
                </FieldLabel>

                <div v-if="!editMode">
                    <slot
                        name="option"
                        v-bind="{ option: getSelectedOption(props.value) }"
                    >
                        {{ getSelectedValue(props.value) }}
                    </slot>
                </div>
                <Dropdown
                    v-if="editMode"
                    v-bind="$filterBoolAttrs($attrs)"
                    ref="select"
                    :data-test="name"
                    :options="options"
                    :data-provider="dataProvider"
                    :resource="resource"
                    :request-params="requestParams"
                    :response-mapper="responseMapper"
                    :item-mapper="itemMapper"
                    :lazy-load="lazyLoad"
                    :page-size="pageSize"
                    :api-search-param-name="apiSearchParamName"
                    :multiple="multiple"
                    :object-mode="objectMode"
                    :track-by="optionKey"
                    :label="optionLabel"
                    :placeholder="placeholder"
                    :searchable="searchable"
                    :local-filtering-function="localFilteringFunction"
                    :no-clear="noClear"
                    :disabled="$boolAttr(disabled)"
                    :preselect-first-option="preselectFirstOption"
                    :model-value="props.value"
                    :select-all="selectAll"
                    :validation-error="props.meta.error && props.meta.touched && !disabled"
                    :loading-dropdown-items="loadingDropdownItems"
                    :size="size"
                    :pagination-mode="paginationMode"
                    @update:model-value="value => props.change(value)"
                    @optionsLoaded="val => $emit('options-loaded', val)"
                    @scrolledToBottom="$emit('scrolled-to-bottom')"
                    @cleared="$emit('cleared', name)"
                    @open="handleSelectOpen(props)"
                    @close="props.events.blur()"
                >
                    <template #tags="params">
                        <slot
                            name="tags"
                            v-bind="$filterBoolAttrs(params)"
                        />
                    </template>
                    <template #multiple-choice="params">
                        <slot
                            name="multiple-choice"
                            v-bind="$filterBoolAttrs(params)"
                        />
                    </template>
                    <template #option="params">
                        <slot
                            name="option"
                            v-bind="$filterBoolAttrs(params)"
                        />
                    </template>
                    <template #option-content="params">
                        <slot
                            name="option-content"
                            v-bind="$filterBoolAttrs(params)"
                        />
                    </template>
                    <template #empty-result>
                        <slot name="empty-result" />
                    </template>
                    <template #option-trailing-icon="{ option }">
                        <slot
                            name="option-trailing-icon"
                            :option="option"
                        />
                    </template>
                </Dropdown>
                <span
                    v-if="hint"
                    class="form-hint"
                >{{ hint }}</span>
                <FieldError
                    v-if="!suppressError"
                    :name="name"
                />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import Dropdown from '@/components/ui/Dropdown';
import FieldLabel from '@/components/form/FieldLabel';
import { PaginationMode } from '@/components/ui/Dropdown';
import FieldError from '@/components/form/FieldError';

export default {
    name: 'SelectInput',
    components: {
        Dropdown,
        FinalField,
        FieldLabel,
        FieldError,
    },

    inject: ['registerFilter', 'unregisterFilter'],

    props: {
        /**
         * The name of the field in the form
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * The label text for the field
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * The hint text for the field
         */
        hint: {
            type: String,
            default: '',
        },

        /**
         * Array of available options
         */
        options: {
            type: Array,
            default: () => [],
        },

        /**
         * Data provider to fetch options
         */
        dataProvider: {
            type: Object,
            default: undefined,
        },

        /**
         * Name of a resource used by the data provider
         */
        resource: {
            type: String,
            default: undefined,
        },

        /**
         * Additional parameters that could be sent with options requests
         */
        requestParams: {
            type: Object,
            default: () => {},
        },

        /**
         * Data mapping function applied to each response
         */
        responseMapper: {
            type: Function,
            default: undefined,
        },

        /**
         * Data mapping function applied to each item from a response
         */
        itemMapper: {
            type: Function,
            default: undefined,
        },

        /**
         * Should options be loaded partially while scrolling the options list
         */
        lazyLoad: {
            type: Boolean,
            required: false,
        },

        /**
         * Number of options that could partially loaded in the lazy mode
         */
        pageSize: {
            type: Number,
            default: undefined,
        },

        /**
         * Name of parameter to pass for API search
         */
        apiSearchParamName: {
            type: String,
            default: 'search',
        },

        /**
         * Equivalent to the `multiple` attribute on a `<select>` input
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Should the value be option object or just key
         */
        objectMode: {
            type: Boolean,
            default: false,
        },

        /**
         * Key to find options
         */
        optionKey: {
            type: String,
            default: 'key',
        },

        /**
         * Label to look for in option object
         */
        optionLabel: {
            type: String,
            default: 'value',
        },

        /**
         * A field-level validation function or an array of functions
         */
        validate: {
            type: [Function, Array],
            default: () => {},
        },

        /**
         * Equivalent to the `placeholder` attribute on a `<select>` input
         */
        placeholder: {
            type: String,
            default: undefined,
        },

        /**
         * Enable/disable search in options
         */
        searchable: {
            type: Boolean,
            required: false,
            default: true,
        },

        /**
         * Custom options filtering function.
         * Receives 2 parameters - search query and unfiltered options array. Must return array of filtered options
         */
        localFilteringFunction: {
            type: Function,
            default: undefined,
        },

        /**
         * Switches off the Clear button
         */
        noClear: {
            type: Boolean,
            required: false,
        },

        /**
         * If the control should be active, or just be presented as a text value
         */
        editMode: {
            type: Boolean,
            default: true,
        },

        /**
         * If the control should be disabled
         */
        disabled: {
            type: Boolean,
            required: false,
        },

        /**
         * Hide error message
         */
        suppressError: {
            type: Boolean,
            required: false,
            default: false,
        },

        /**
         * Select first option by default
         */
        preselectFirstOption: {
            type: Boolean,
            default: false,
        },

        /**
         * Allow select all options
         */
        selectAll: {
            type: Boolean,
            default: false,
        },

        /**
         * Display loading when waiting for options to load
         */
        loadingDropdownItems: {
            type: Boolean,
            default: false,
        },

        /**
         * Size for the field
         */
        size: {
            type: String,
            default: 'normal',
            validator: function (value) {
                return ['normal', 'small', 'xsmall'].includes(value);
            },
        },

        /**
         * Display icon with tooltip
         */
        tooltip: {
            type: String,
            default: '',
        },

        /**
         * Vertical position for tooltip (bottom, top, auto)
         */
        tooltipVerticalPosition: {
            type: String,
            default: undefined,
        },

        /**
         * Pagination mode for data provider: basic or token based
         */
        paginationMode: {
            type: String,
            default: PaginationMode.BASIC,
            validator: function (value) {
                return Object.values(PaginationMode).includes(value);
            },
        },
    },

    emits: ['options-loaded', 'cleared', 'scrolled-to-bottom', 'open'],

    mounted() {
        if (typeof this.registerFilter === 'function') {
            this.registerFilter(this);
        }
    },

    beforeUnmount() {
        if (typeof this.registerFilter === 'function') {
            this.unregisterFilter(this);
        }
    },

    methods: {
        getSelectedValue(value) {
            if (this.multiple) {
                return (
                    value
                        .map(val => this.getSelectedOption(val)?.[this.optionLabel])
                        .filter(v => v)
                        .join(', ') || '-'
                );
            } else {
                return this.getSelectedOption(value)?.[this.optionLabel] ?? '-';
            }
        },

        getSelectedOption(value) {
            if (value == null) {
                return this.options.find(option => option[this.optionKey] == null);
            } else if (typeof value === 'object') {
                return value;
            } else {
                return this.options.find(option => option[this.optionKey] === value);
            }
        },

        handleSelectOpen(props) {
            props.events.focus();
            this.$emit('open');
        },
    },
};
</script>

<template>
    <div class="modal-footer">
        <div>
            <button
                v-if="footer.tertiaryButton"
                type="button"
                class="btn-primary btn-transparent"
                :disabled="$boolAttr(disableTertiary)"
                @click.prevent="tertiary"
            >
                {{ footer.tertiaryButton.toLowerCase() }}
            </button>
        </div>
        <div class="flex">
            <CheckboxInput
                v-if="withCheckbox"
                name="checkboxValue"
                :label="footer.checkboxButton"
                class="mr-4"
                @change="$emit('checkboxValue', $event)"
            />
            <button
                v-if="footer.secondaryButton"
                type="button"
                :class="secondaryClass"
                class="mr-4"
                :disabled="$boolAttr(disableSecondary)"
                @click="secondary"
            >
                {{ footer.secondaryButton.toLowerCase() }}
            </button>
            <button
                v-if="footer.primaryButton"
                :class="primaryClass"
                :disabled="$boolAttr(disablePrimary || primaryButtonDisabled)"
                @click="primary"
            >
                {{ footer.primaryButton.toLowerCase() }}
            </button>
        </div>
    </div>
</template>

<script>
import ModalFooterMixin from '@/components/ui/modals/ModalFooterMixin';
import EventBus from '@/utils/EventBus';
import CheckboxInput from '@/components/auth/form/CheckboxInput';

export default {
    components: {
        CheckboxInput,
    },

    mixins: [ModalFooterMixin],
    props: {
        footer: {
            type: Object,
            required: false,
            default: () => {
                return {
                    primaryButton: 'Save',
                    secondaryButton: '',
                    tertiaryButton: '',
                    checkboxButton: '',
                };
            },
        },

        primary: {
            type: Function,
            required: false,
            default: () => {
                window.console.log('primary button clicked');
            },
        },

        secondary: {
            type: Function,
            required: false,
            default: () => {
                window.console.log('secondary button clicked');
            },
        },

        tertiary: {
            type: Function,
            required: false,
            default: () => {
                window.console.log('tertiary button clicked');
            },
        },

        primaryClass: {
            type: String,
            default: 'btn-primary btn-solid',
        },

        secondaryClass: {
            type: String,
            default: 'btn-primary',
        },

        disablePrimary: {
            type: Boolean,
            default: false,
        },

        disableSecondary: {
            type: Boolean,
            default: false,
        },

        disableTertiary: {
            type: Boolean,
            default: false,
        },

        withCheckbox: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['checkboxValue'],
    data() {
        return {
            primaryButtonDisabled: false,
            checkboxValue: false,
        };
    },

    mounted() {
        EventBus.on('disable-primary-footer-button', () => {
            this.primaryButtonDisabled = true;
        });
    },
};
</script>

import { computed } from 'vue';
import { useAccountingStore } from '@/stores/accounting';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';

export function useVendorsMenuItem() {
    const acctStore = useAccountingStore();

    const hasAccessToVendorsSection = computed(() => {
        return acctStore.hasPermission(USER_PERMISSIONS.ACCT_MANAGE_VENDORS);
    });

    const vendorsMenuItems = computed(() => ({
        items: [
            {
                icon: 'shopping-bag',
                permissionChecker: hasAccessToVendorsSection.value,
                label: 'Vendors',
                id: '__vendors',
                children: [
                    {
                        label: 'Manage vendors',
                        id: 'vendor.manage_vendors',
                    },
                ],
            },
        ],
    }));

    return { vendorsMenuItems };
}

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import FilemanagerDataProvider from '@/api/filemanager';

const store = useStore();

const community = computed(() => store.getters['auth/community']);

watch(
    () => community.value?.id,
    id => {
        if (id) {
            fetchCommunityFilesLibrary();
        } else {
            store.dispatch('files/clearLibrary');
        }
    },
    { immediate: true }
);

async function fetchCommunityFilesLibrary() {
    try {
        const response = await FilemanagerDataProvider.adapter.get('communityFiles', {
            communityId: community.value.id,
        });
        const files = response.map(file => ({
            file_uuid: file.file_uuid,
            base_directory: file.base_directory,
            community_id: file.community_id,
            original_name: file.file_name,
            file_url: file.file_url,
            thumbnail_url: file.thumbnail_url,
            file_type: file.file_type,
            file_size: file.file_size,
            created_at: file.created_at,
            updated_at: file.updated_at,
            tags: [],
        }));
        store.dispatch('files/loadLibrary', files);
    } catch (error) {
        console.error(error);
    }
}
</script>

import { computed } from 'vue';

export function useHomeMenuItem() {
    const homeMenuItems = computed(() => ({
        items: [
            {
                icon: 'home-dash',
                label: 'Home',
                id: 'home',
            },
            {
                icon: 'calendar-2',
                label: 'Calendar',
                id: 'calendar_events.index',
            },
            {
                icon: 'file-text',
                label: 'Reporting',
                id: 'dashboard',
            },
        ],
    }));

    return { homeMenuItems };
}

/**
 * Processes a boolean attribute, returning null if the value is false.
 * This function is useful for handling boolean attributes in Vue components,
 * ensuring that attributes are not rendered in the DOM when their value is false.
 *
 * @param {any} value - The value of the attribute.
 * @returns {boolean|null} - Returns true if the value is true, or null if the value is false.
 *
 * @example
 * // Usage in a Vue component:
 * const disabledAttr = boolAttr(false); // Returns null, removing the 'disabled' attribute from the DOM.
 * const checkedAttr = boolAttr(true);   // Returns true, keeping the 'checked' attribute in the DOM.
 */
function boolAttr(value) {
    return Boolean(value) || null;
}

/**
 * Filters boolean and enumerated attributes from the given attributes object.
 * Converts the values of specified boolean keys to true, false, or null,
 * and handles enumerated attributes according to Vue 3 behavior.
 *
 * @param {Object} attrs - The attributes object to filter.
 * @returns {Object} The filtered attributes object with processed values.
 */
export const filterBoolAttrs = attrs => {
    const boolAttrs = ['checked', 'disabled', 'indeterminate'];
    const enumeratedAttrs = ['contenteditable', 'draggable', 'spellcheck'];

    return Object.entries(attrs || {}).reduce((filteredAttrs, [attr, value]) => {
        if (boolAttrs.includes(attr)) {
            filteredAttrs[attr] = Boolean(value) || null;
        } else if (enumeratedAttrs.includes(attr)) {
            if (value === true || value === 'true') {
                filteredAttrs[attr] = 'true';
            } else if (value === false || value === 'false') {
                filteredAttrs[attr] = 'false';
            } else {
                filteredAttrs[attr] = null;
            }
        } else {
            filteredAttrs[attr] = value;
        }
        return filteredAttrs;
    }, {});
};

export default {
    /**
     * Installs the boolAttr utility as a global property in the Vue application.
     * This allows the utility to be accessed in any component via `this.$boolAttr`.
     *
     * @param {Object} app - The Vue application instance.
     */
    install(app) {
        app.config.globalProperties.$boolAttr = boolAttr;
        app.config.globalProperties.$filterBoolAttrs = filterBoolAttrs;
    },
};

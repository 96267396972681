import { shallowRef } from 'vue';
import { useStore } from 'vuex';
import { useAlertStore } from '@/stores/alert';
import EventBus from '@/utils/EventBus';
import AuthDataProvider from '@/api/auth';
import { REFRESH_PERMISSIONS_EVENT } from '@/components/permission_service/constants';

export default function useCommunityOptions() {
    const store = useStore();
    const { notifyError } = useAlertStore();
    const communityLoading = shallowRef(false);
    const communities = shallowRef([]);

    async function fetchCommunities() {
        communityLoading.value = true;
        try {
            const { content = [] } = await AuthDataProvider.adapter.get('userCommunities', {
                size: 999,
                nonDeleted: true,
                sort: 'name,asc',
            });
            communities.value = content.map(({ id, name, timezoneId }) => ({ id, name, timezoneId }));
            store.dispatch('auth/setCommunities', communities.value);

            if (communities.value.length === 1) {
                store.dispatch('auth/setCommunity', communities.value[0]);
                EventBus.emit(REFRESH_PERMISSIONS_EVENT);
            }
        } catch {
            notifyError('Failed to fetch data for communities selector');
        } finally {
            communityLoading.value = false;
        }
    }

    return {
        communityLoading,
        communities,
        fetchCommunities,
    };
}

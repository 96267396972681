import { computed } from 'vue';
import { hasAnyCommunitySettingsAccess } from '@/community_settings';

export function useCommunitySettingsMenuItem() {
    const communitySettingsMenuItems = computed(() => ({
        items: [
            {
                icon: 'unit',
                id: '__community_settings',
                label: 'Community settings',
                permissionChecker: hasAnyCommunitySettingsAccess(),
                children: [
                    {
                        label: 'Community settings',
                        id: 'community.settings.index',
                    },
                ],
            },
        ],
    }));

    return { communitySettingsMenuItems };
}

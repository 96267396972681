import { computed } from 'vue';

export function useSearchEngine() {
    const searchEngineMenuItems = computed(() => ({
        items: [
            {
                icon: 'search',
                id: '__search_engine',
                label: 'Search engine',
                children: [
                    {
                        label: 'Search engine dashboard',
                        id: 'searchEngineDashboard.index',
                    },
                ],
            },
        ],
    }));

    return { searchEngineMenuItems };
}

<template>
    <App3 v-if="v3" />
    <App v-else />
</template>

<script setup>
import { ref, provide } from 'vue';
import App from '@/App.vue';
import App3 from '@/App3.vue';
import { storeToRefs } from 'pinia';
import { useUIStore } from '@/stores/ui';
import useSearchableRoutes from '@/composables/search/useSearchableRoutes';

const { v3 } = storeToRefs(useUIStore());
useSearchableRoutes();

const loading = ref(false);
const setLoading = value => {
    loading.value = value;
};

provide('loading', { loading, setLoading });
</script>

import store from '@/store';
import { isRouteAllowed } from '@/router';
import { SECTIONS } from './sections';

function getRouteName(route, flowStartsWith) {
    const containsDynamicSegments = /:.*/.test(route.path);
    if (!containsDynamicSegments && (route.name.endsWith('.create') || route.name.endsWith('.add'))) {
        return route.name;
    } else {
        return flowStartsWith || route.name;
    }
}
function transformTaskToRoute(task, routeMap) {
    const result = routeMap.get(task.name);
    if (result) {
        result.data = task;
    }
    return result;
}

function checkAllowedRoutes(route) {
    if (!route || !route.meta) {
        return false;
    }

    return (
        route.meta.title && !route.meta.hideFromSearch && isRouteAllowed(route) && (!route.meta.searchFilter || route.meta.searchFilter())
    );
}

function formatSearchRoutes(route) {
    const { title, product, flowStartsWith, passFlowTo, pathProps } = route.meta;
    const fullTitle = product ? `${title} (${product})` : title;
    const routeName = getRouteName(route, flowStartsWith);
    return {
        id: route.name,
        section: SECTIONS.TASKS,
        title: fullTitle,
        product,
        route: routeName,
        routeParams: {
            passFlowTo: passFlowTo ?? null,
            ...(pathProps?.() ?? null),
        },
        resultId: route.data.id,
    };
}

export default async tasks => {
    const allSearchableRoutes = store.getters['search_routes/getSearchableRoutes'];
    const routeMap = new Map(allSearchableRoutes.map(route => [route.name, route]));
    return tasks
        .map(task => transformTaskToRoute(task, routeMap))
        .filter(route => checkAllowedRoutes(route))
        .map(formatSearchRoutes);
};

import { computed } from 'vue';
import { useAuthPermissions } from '@/components/auth/permissions';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners';
import IPSV2_PERMISSIONS from '@/utils/constants/integrations_partners_v2/permissions.js';
import DCS_PERMISSIONS from '@/utils/constants/data_consuming_system/permissions';

export function useQuextSetupMenuItem() {
    const { hasAuthPermission, QUEXT_PERMISSIONS: Q_PERM } = useAuthPermissions();

    const { hasPermission, hasRolePermission } = useIntegrationPartnersStore();

    const hasPartnerAccess = computed(() => {
        return hasPermission(IPSV2_PERMISSIONS.CREATE_PARTNER) || hasPermission(IPSV2_PERMISSIONS.UPDATE_PARTNER) || hasRolePermission;
    });

    const hasSyncServiceAccess = computed(() => {
        return hasPermission(DCS_PERMISSIONS.VIEW_SCHEDULES) && hasRolePermission;
    });

    const quextSetupMenuItems = computed(() => ({
        items: [
            {
                icon: 'settings',
                permissionChecker: hasAuthPermission.value(Q_PERM.QUEXT_SETUP),
                label: 'Quext setup',
                id: '__quext_setup',
                children: [
                    {
                        permissionChecker: hasAuthPermission.value(Q_PERM.VIEW_CUSTOMERS),
                        label: 'customers',
                        id: 'customers.index',
                    },
                    {
                        permissionChecker:
                            hasAuthPermission.value(Q_PERM.VIEW_PERMISSION_GROUPS) || hasAuthPermission.value(Q_PERM.VIEW_METAROLES),
                        label: 'customer roles management',
                        id: 'customer_roles_mngmt',
                    },
                    {
                        permissionChecker:
                            hasAuthPermission.value(Q_PERM.VIEW_SUPERADMIN) ||
                            hasAuthPermission.value(Q_PERM.VIEW_QUEXT_USERS) ||
                            hasAuthPermission.value(Q_PERM.VIEW_PRESETS),
                        label: 'quext users and roles management',
                        id: 'quext_users_roles_mngmt',
                    },
                    {
                        permissionChecker: hasAuthPermission.value(Q_PERM.VIEW_APPS),
                        label: 'applications',
                        id: 'application_mngmt.index',
                    },
                    {
                        permissionChecker: hasPartnerAccess.value,
                        label: 'integration partners',
                        id: 'ips-v2.partner.manage-partner',
                    },
                    {
                        permissionChecker: hasSyncServiceAccess.value,
                        label: 'sync service',
                        id: 'sync-service.index',
                    },
                    {
                        permissionChecker: hasAuthPermission.value(Q_PERM.VIEW_SUPERADMIN),
                        label: 'reports settings',
                        id: 'dashboard.json-editor',
                    },
                ],
            },
        ],
    }));

    return { quextSetupMenuItems };
}

import { SECTIONS } from './sections';
export default async miscellaneous => {
    return miscellaneous
        .filter(route => {
            return !route.meta || !route.meta.customerId ? false : route;
        })
        .map(route => {
            return {
                id: 'manage_residents.resident-details',
                section: SECTIONS.MISCELLANEOUS,
                title: route.meta.category === 'VEHICLE' ? `${route.meta.title} (Vehicle)` : `${route.meta.title} (Pet)`,
                route: 'manage_residents.resident-details',
                routeParams: {
                    id: route.meta.occupancyId,
                    leaseEndDate: new Date().toISOString(),
                },
                resultId: route.meta.objectId,
            };
        });
};

<template>
    <FinalField
        ref="field"
        :name="name"
        :validate="validate"
    >
        <template #default="props">
            <div :class="['form-col', $attrs['class']]">
                <FieldLabel
                    v-if="label || !!$slots['label']"
                    :name="name"
                    :tooltip="tooltip"
                >
                    <slot name="label">
                        {{ label }}
                    </slot>
                </FieldLabel>

                <CounterInput
                    :model-value="props.value"
                    :class="inputClass"
                    v-bind="$filterBoolAttrs({ ...inputAttrs, ...getCounterProps(props) })"
                >
                    <template #icon>
                        <slot name="icon" />
                    </template>
                </CounterInput>

                <FieldError
                    v-if="!suppressError"
                    :name="name"
                />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { omit } from 'lodash-es';
import { FinalField } from 'vue-final-form';
import CounterInput from '@/components/ui/CounterInput';
import FieldLabel from '@/components/form/FieldLabel';
import FieldError from '@/components/form/FieldError';

export default {
    components: { CounterInput, FieldLabel, FieldError, FinalField },

    inheritAttrs: false,

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: false,
            default: undefined,
        },

        validate: {
            type: [Function, Array],
            required: false,
            default: undefined,
        },

        editMode: {
            type: Boolean,
            default: true,
        },

        textClass: {
            type: String,
            required: false,
            default: undefined,
        },

        inputClass: {
            type: String,
            required: false,
            default: undefined,
        },

        suppressError: {
            type: Boolean,
            required: false,
            default: false,
        },

        tooltip: {
            type: String,
            default: '',
        },
    },

    computed: {
        inputAttrs() {
            return omit(this.$attrs, 'class');
        },
    },

    methods: {
        handleChange(value) {
            this.$refs.field.fieldState.change(value);
        },

        getCounterProps(props) {
            if (this.editMode) {
                return {
                    name: props.name,
                    amountClass: [this.textClass, { error: props.meta.error && props.meta.touched }],
                    onFocus: props.events.focus,
                    onBlur: props.events.blur,
                    'onUpdate:modelValue': this.handleChange,
                };
            }

            return {
                amountClass: this.textClass,
                readOnly: true,
            };
        },
    },
};
</script>

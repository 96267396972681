import { computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default function useSearchableRoutes() {
    const router = useRouter();
    const store = useStore();

    const appsPermissions = computed(() => store.getters['auth/appsPermissions']);
    const communityAppCodes = computed(() => store.getters['auth/customerCommunityAppCodes']);

    watch(
        [() => appsPermissions.value, () => communityAppCodes.value],
        () => {
            recalculateSearchableRoutes();
        },
        { deep: true }
    );

    function recalculateSearchableRoutes() {
        const routes = getFlattenRoutes(router.options.routes);
        store.dispatch('search_routes/loadSearchableRoutes', routes);
    }

    function getFlattenRoutes(routes) {
        if (!routes) {
            return [];
        }

        return routes.reduce((acc, route) => {
            if (
                route.meta?.title &&
                route.meta?.flowStartsWith &&
                !route.meta?.hideFromSearch &&
                (!route.meta?.permissionChecker || route.meta.permissionChecker())
            ) {
                acc.push(route);
            }
            acc.push(...getFlattenRoutes(route.children));
            return acc;
        }, []);
    }

    onMounted(() => {
        recalculateSearchableRoutes();
    });
}

<template>
    <QxtDropdownMenu
        v-if="route.meta && route.meta.actions && actions?.length"
        ref="dropdownMenu"
        class="button"
    >
        <template #activator>
            <QxtIconButton
                icon="more-vertical"
                color="brand"
                size="2xl"
                @click="dropdownMenu?.toggleOpen"
            />
        </template>
        <template #menu>
            <QxtMenu @click="dropdownMenu?.toggleOpen">
                <QxtMenuDropdownOption
                    v-for="(action, index) in actions"
                    :key="index"
                    @click="emitAction(action)"
                >
                    {{ action.title }}
                </QxtMenuDropdownOption>
            </QxtMenu>
        </template>
    </QxtDropdownMenu>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { findRouteByName, isRouteAllowed } from '@/router';
import { QxtIconButton, QxtDropdownMenu, QxtMenu, QxtMenuDropdownOption } from '@asmartment/qui-base';
import EventBus from '@/utils/EventBus';

const dropdownMenu = ref(null);

const EVENTS = {
    showConfirmation: 'show-modal-confirmation',
    isDownload: 'show-modal-download',
    showUploader: 'show-modal-uploader',
    isRegular: '',
};
const getEmitEvent = action => {
    for (const key in EVENTS) {
        if (action[key]) {
            return EVENTS[key] || action.id;
        }
    }

    return false;
};

const store = useStore();
const getActionsByRouteName = store.getters['actions/getActionsByRouteName'];
const profile = store.getters['auth/profile'];

const route = useRoute();

const actions = computed(() =>
    // function from ModalActions.vue, TODO refactor it
    getActionsByRouteName(route.name)
        .filter(action => {
            const routeAllowed = isRouteAllowed(findRouteByName(route.name));
            const actionAllowed = typeof action.allowed === 'undefined' || action.allowed(profile);

            return (
                actionAllowed &&
                (action.isDownload || action.showConfirmation /*|| action.isDHPreview*/ || action.showUploader || routeAllowed) &&
                !action.hideOnFab
            );
        })
        .sort((a, b) => (a.dynamic | 0) - (b.dynamic | 0))
);

// function from ModalActions.vue, TODO refactor it
const bindRouteParams = params => {
    if (typeof params !== 'object') return {};

    const _parameters = {};

    Object.entries(params).forEach(([key, value]) => {
        if (typeof value === 'string') {
            const matches = value.match(/^{(.*)}$/);
            if (matches) {
                _parameters[key] = route.params[matches[1]];
                return;
            }
        }

        _parameters[key] = value;
    });

    return _parameters;
};

const router = useRouter();
const emitAction = action => {
    const emitEvent = getEmitEvent(action);
    if (emitEvent) {
        EventBus.emit(emitEvent, action);
    } else {
        router.push({ name: action.routeName, params: bindRouteParams(action.params), query: action.query });
    }
};
</script>

<style scoped>
.button {
    position: absolute;
    top: 0.75rem;
    right: 1.5rem;
}
</style>

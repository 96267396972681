<template>
    <QxtPropertySelect
        class="ml-4"
        :model-value="selectedLocation"
        :title="val => (val?.id ? val?.name : 'Select company')"
        :subtitle="val => val?.name ?? 'Select community'"
        :entities="propertySelectOptions"
        @update:model-value="handleLocationChange"
    />
    <CommunityFilesLibrary />
</template>

<script setup>
import { QxtPropertySelect } from '@asmartment/qui-base';
import CommunityFilesLibrary from '@/components/layout/v3/CommunityFilesLibrary.vue';

import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useAlertStore } from '@/stores/alert';
import useCustomerOptions from '@/components/layout/v3/composables/useCustomerOptions';
import useCommunityOptions from '@/components/layout/v3/composables/useCommunityOptions';
import EventBus from '@/utils/EventBus';
import AuthDataProvider from '@/api/auth';
import { INIT_CUSTOMERS_FETCH, SIGNED_OUT } from '@/utils/authEvents';
import { REFRESH_PERMISSIONS_EVENT } from '@/components/permission_service/constants';
import { useRouter } from 'vue-router';

const OPTIONS_REFRESH_INTERVAL = 60000;

const store = useStore();
const router = useRouter();
const { notifyError } = useAlertStore();
const { customers, customerContext, fetchCustomers } = useCustomerOptions();
const { communityLoading, communities, fetchCommunities } = useCommunityOptions();

const customersPullingIntervalId = ref(null);

const profile = computed(() => store.getters['auth/profile']);
const profiles = computed(() => store.getters['auth/profiles']);
const community = computed(() => store.getters['auth/community']);

const selectedLocation = computed(() => ({
    customerId: (customerContext.value ? profile.value?.customerId : profile.value?.profileId) ?? null,
    communityId: community.value?.id ?? null,
}));

const propertySelectOptions = computed(() => [
    {
        data: customerContext.value
            ? [
                {
                    id: undefined,
                    name: 'Unselect company',
                },
                ...customers.value,
            ]
            : customers.value,
        icon: 'building',
        label: 'Company',
        name: 'customerId',
    },
    {
        data: communities.value,
        icon: 'building-2',
        label: 'Community',
        name: 'communityId',
        loading: communityLoading.value,
    },
]);

watch(
    () => profile.value?.customerId,
    val => {
        if (val) {
            fetchCommunities();
        } else {
            communities.value = [];
            store.dispatch('auth/setCommunity', null);
            store.dispatch('auth/setCommunities', []);
            store.dispatch('auth/setCustomerApps', []);
        }
    },
    { immediate: true }
);

watch(
    () => community.value?.id,
    val => {
        if (val) {
            fetchCommunityAppCodes();
        } else {
            store.dispatch('auth/setCustomerCommunityAppCodes', []);
        }
    },
    { immediate: true }
);

async function handleLocationChange({ customerId = null, communityId = null }) {
    if (
        (selectedLocation.value.customerId && selectedLocation.value.customerId !== customerId) ||
        (selectedLocation.value.communityId && selectedLocation.value.communityId !== communityId)
    ) {
        await router.replace({
            name: 'home',
        });
    }

    if (selectedLocation.value.customerId !== customerId) {
        handleCustomerSelect(customerId);
    }
    if (selectedLocation.value.communityId !== communityId) {
        store.dispatch('auth/setCommunity', communities.value.find(item => item.id === communityId) ?? null);
    }
    EventBus.emit(REFRESH_PERMISSIONS_EVENT);
}

function handleCustomerSelect(customerId) {
    let newProfile = profiles.value?.[0];
    if (customerId) {
        const customer = customers.value.find(item => item.id === customerId);

        newProfile = customerContext.value
            ? {
                ...profile.value,
                customerId: customer?.id,
                customerName: customer?.name,
            }
            : customer;
    }

    store.dispatch('auth/setProfile', newProfile);
}

async function fetchCommunityAppCodes() {
    try {
        const { content = [] } = await AuthDataProvider.adapter.get('communityLicensedAppAccess', {
            customerId: profile.value.customerId,
            communityId: community.value.id,
        });
        store.dispatch(
            'auth/setCustomerCommunityAppCodes',
            content.map(app => app.appCode)
        );
    } catch {
        notifyError('Failed to fetch applications authorized for the selected community');
    }
}

onMounted(async () => {
    await fetchCustomers();

    customersPullingIntervalId.value = setInterval(fetchCustomers, OPTIONS_REFRESH_INTERVAL);

    EventBus.on(INIT_CUSTOMERS_FETCH, fetchCustomers);
    EventBus.on(SIGNED_OUT, () => {
        if (customersPullingIntervalId.value) {
            clearInterval(customersPullingIntervalId.value);
        }
    });
});

onBeforeUnmount(() => {
    clearInterval(customersPullingIntervalId.value);
});
</script>

import SearchDataProvider from '@/api/search';
import filterTasks from './tasks';
import filterPeople from './people';
import filterVendor from './vendor';
import filterSpaces from './spaces';
import filterMisc from './miscellaneous';

export default async query => {
    const apiResult = await SearchDataProvider.adapter.getSearchApiResult('apiResult', { data: { query } });
    const searchResult = { person: [], tasks: [], vendors: [], spaces: [], miscellaneous: [] };

    apiResult.results.forEach(item => {
        if (item?.meta?.type) {
            switch (item.meta.type) {
                case 'person':
                    return searchResult.person.push(item);
                case 'task':
                    return searchResult.tasks.push(item);
                case 'vendor':
                    return searchResult.vendors.push(item);
                case 'floorplan':
                case 'space':
                case 'unittype':
                    return searchResult.spaces.push(item);
                case 'miscellaneous':
                    return searchResult.miscellaneous.push(item);
                default:
                    break;
            }
        }
    });

    const taskRoutes = await filterTasks(searchResult.tasks);
    const peopleRoutes = await filterPeople(searchResult.person);
    const vendorRoutes = await filterVendor(searchResult.vendors);
    const spaceRoutes = await filterSpaces(searchResult.spaces);
    const miscellaneousRoutes = await filterMisc(searchResult.miscellaneous);

    return {
        tasks: taskRoutes,
        people: peopleRoutes,
        vendors: vendorRoutes,
        locations: spaceRoutes,
        miscellaneous: miscellaneousRoutes,
    };
};

import { computed } from 'vue';
import { useEmailbotStore } from '@/stores/emailbot/emailbot';

export function useEmailBotItem() {
    const { EMAILBOT_PERMISSIONS, hasPermission } = useEmailbotStore();

    const hasAnyEmailbotAccess = computed(
        () =>
            hasPermission(EMAILBOT_PERMISSIONS.VIEW_EMAILBOT) ||
            hasPermission(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE) ||
            hasPermission(EMAILBOT_PERMISSIONS.VIEW_CATEGORY)
    );

    const emailBotItems = computed(() => ({
        items: [
            {
                icon: 'mail',
                id: '__email_bot',
                label: 'Email bot',
                permissionChecker: hasAnyEmailbotAccess.value,
                children: [
                    {
                        label: 'Configurations',
                        id: 'emailbot.configurations.index',
                    },
                    {
                        label: 'Templates',
                        id: 'emailbot.templates.index',
                    },
                ],
            },
        ],
    }));

    return { emailBotItems };
}

/**
 * Applies a mask to a given input value based on a provided mask pattern.
 * The function processes each character of the value and mask, applying transformations or validations
 * as specified by tokens. It handles static characters in the mask, escaped characters, and optional transformation.
 *
 * @param {string} [value=''] - The input value to be masked.
 * @param {string} [mask=''] - The mask pattern to apply to the input value.
 * @param {boolean} [masked=true] - Whether to include mask characters in the output (true) or only return unmasked value (false).
 * @param {Object} [tokens={}] - A set of tokens where each key represents a mask character and each value is an object
 * containing properties `pattern` (RegExp) for matching input and `transform` (optional function) to modify the input.
 *
 * @returns {string} - The masked output, combining the input value and the mask pattern.
 */
export function maskIt(value = '', mask = '', masked = true, tokens = {}) {
    let iMask = 0;
    let iValue = 0;
    let output = '';

    while (iMask < mask.length && iValue < value.length) {
        let cMask = mask[iMask];
        const masker = tokens[cMask];
        const cValue = value[iValue];

        if (masker && !masker.escape) {
            if (masker.pattern.test(cValue)) {
                output += masker.transform ? masker.transform(cValue) : cValue;
                iMask++;
            }
            iValue++;
        } else {
            if (masker && masker.escape) {
                iMask++; // take the next mask char and treat it as char
                cMask = mask[iMask];
            }
            if (masked) output += cMask;
            if (cValue === cMask) iValue++; // user typed the same char
            iMask++;
        }
    }

    // handle the rest of the mask
    let restOutput = '';
    while (iMask < mask.length && masked) {
        const cMask = mask[iMask];
        if (tokens[cMask]) {
            restOutput = '';
            break;
        }
        restOutput += cMask;
        iMask++;
    }

    return output + restOutput;
}

/**
 * Returns a function that applies dynamic masking to a given value based on the provided masks.
 * The function sorts the masks by their length and applies the appropriate mask based on the length of the input value.
 * It checks if the next mask in the list is suitable, otherwise it applies the current mask.
 *
 * @param {Function} maskIt - The function responsible for applying the mask to the value.
 * @param {Array} masks - An array of masks to apply to the value, sorted by length.
 * @param {Object} [tokens={}] - Optional tokens that can be used by the maskIt function.
 *
 * @returns {Function} - A function that takes a value, a mask, and a masked flag, and returns the masked value.
 */
export function dynamicMask(maskIt, masks, tokens = {}) {
    masks = masks.sort((a, b) => a.length - b.length);

    return (value, mask, masked = true) => {
        for (let i = 0; i < masks.length; i++) {
            const currentMask = masks[i];
            const nextMask = masks[i + 1];

            // Check if the next mask should be used
            if (!nextMask || maskIt(value, nextMask, true, tokens).length <= currentMask.length) {
                return maskIt(value, currentMask, masked, tokens);
            }
        }

        // Empty string if no mask is valid
        return '';
    };
}

// Facade to maskIt/dynamicMask when mask is String or Array
export function masker(value, mask, masked = true, tokens) {
    return Array.isArray(mask) ? dynamicMask(maskIt, mask, tokens)(value, mask, masked, tokens) : maskIt(value, mask, masked, tokens);
}

/**
 * Default tokens for masking input values.
 *
 * The tokens object defines various characters and their corresponding patterns or transformations
 * to be used in the masking process. Each key represents a mask character, and each value is an object
 * containing properties:
 * - `pattern` (RegExp): A regular expression to match the input character.
 * - `transform` (optional function): A function to transform the input character.
 * - `escape` (boolean): If true, the character is treated as a literal character in the mask.
 */
export const TOKENS = {
    '#': { pattern: /\d/ },
    X: { pattern: /[0-9a-zA-Z]/ },
    S: { pattern: /[a-zA-Z]/ },
    A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
    a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
    '!': { escape: true },
};

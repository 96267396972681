export const SECTIONS = {
    TASKS: 'tasks',
    PEOPLE: 'people',
    VENDORS: 'vendors',
    LOCATIONS: 'locations',
    MISCELLANEOUS: 'miscellaneous',
};

export const SECTION_TITLES = {
    [SECTIONS.TASKS]: 'actions',
    [SECTIONS.PEOPLE]: 'people',
    [SECTIONS.VENDORS]: 'vendors',
    [SECTIONS.LOCATIONS]: 'locations',
    [SECTIONS.MISCELLANEOUS]: 'miscellaneous',
};

export const SECTION_TYPES = {
    [SECTIONS.TASKS]: 'action',
    [SECTIONS.PEOPLE]: null,
    [SECTIONS.VENDORS]: 'vendor',
    [SECTIONS.LOCATIONS]: 'location',
    [SECTIONS.MISCELLANEOUS]: 'misc',
};

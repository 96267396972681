<template>
    <transition name="slide-in">
        <div
            v-if="show"
            class="absolute top-0 right-0 w-2/5 h-full bg-white p-6 border-l z-10"
        >
            <IconButton
                icon="close"
                class="absolute top-3 right-3 z-10"
                @click="closeModalDrawer"
            />

            <Component
                :is="currentComponent"
                v-bind="$filterBoolAttrs(currentProps)"
            />
        </div>
    </transition>
</template>

<script>
import EventBus from '@/utils/EventBus';
import IconButton from '@/components/ui/IconButton.vue';

export default {
    components: { IconButton },
    data() {
        return {
            show: false,
            currentComponent: undefined,
            currentProps: {},
        };
    },

    created() {
        EventBus.on('open-modal-drawer', this.openModalDrawer);
        EventBus.on('close-modal-drawer', this.closeModalDrawer);
        EventBus.on('navigateForward', this.closeModalDrawer);
        EventBus.on('navigateBack', this.closeModalDrawer);
    },

    beforeUnmount() {
        EventBus.off('open-modal-drawer', this.openModalDrawer);
        EventBus.off('close-modal-drawer', this.closeModalDrawer);
        EventBus.off('navigateForward', this.closeModalDrawer);
        EventBus.off('navigateBack', this.closeModalDrawer);
    },

    methods: {
        openModalDrawer({ component, props }) {
            this.show = true;
            this.currentComponent = component;
            this.currentProps = props;
        },

        closeModalDrawer() {
            this.show = false;
            this.currentComponent = undefined;
            this.currentProps = {};
        },
    },
};
</script>

<style scoped>
.slide-in-enter-active {
    transition: all 0.3s ease;
}
.slide-in-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-in-enter-from,
.slide-in-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>

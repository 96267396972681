import { SECTIONS } from './sections';
function filterOutIncompleteRoutes(route) {
    {
        const hasMeta = !!route.meta;
        const hasCustomerId = !!route.meta?.customerId;
        const residentApp = route.meta.app === 'resident';
        const occupancyTypeApplicant = route.meta?.occupancyType === 'applicant';
        const hasOccupancyId = !!route.meta?.occupancyId;
        if (!hasMeta || !hasCustomerId || occupancyTypeApplicant || (residentApp && !hasOccupancyId)) {
            return false;
        }
        return !!route;
    }
}

function mapRoutesToSearchResultData({ meta }) {
    const residentApp = meta.app === 'resident';
    const title = residentApp ? `${meta.title} (${meta.occupancyType || 'resident'})` : `${meta.title} (user)`;
    const route = residentApp ? 'manage_residents.resident-details' : 'company.users.details';
    const routeParams = residentApp
        ? {
            id: meta.occupancyId,
            leaseEndDate: new Date().toISOString(),
        }
        : {
            customerId: meta.customerId,
            userId: meta.objectId,
            profileId: meta.objectId,
        };
    const dependentResult = meta.units
        ? {
            type: 'units',
            results: meta.units.slice(0, 5).map(unit => ({
                ...unit,
                route: 'cbr.living.units.details',
                routeParams: { unitId: unit.spaceid },
            })),
        }
        : null;

    return {
        id: route,
        section: SECTIONS.PEOPLE,
        title,
        product: meta.product,
        route,
        routeParams,
        resultId: meta.objectId,
        dependentResult,
    };
}

export default async people => {
    return people.filter(filterOutIncompleteRoutes).map(mapRoutesToSearchResultData);
};

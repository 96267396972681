<template>
  <label
    class="switch"
    :class="[
      sizeClass,
      { 'switch--disabled': disabled },
      { 'switch--active': active }
    ]"
  >
    <input
      type="checkbox"
      @change="toggle"
      :checked="$boolAttr(modelValue)"
      :disabled="$boolAttr(disabled)"
      :name="name"
    />
    <span
      class="switch__lever"
      :class="{ 'mr-2': inputLabel }"
    />
    {{ inputLabel }}
    <tooltip
      v-if="disabled && disableReason"
      icon="exclamationCircle"
      :text="disableReason"
      :arrow="false"
      class="text-left ml-1"
    />
  </label>
</template>

<script>
  import Tooltip from "@/components/ui/Tooltip";
  import ConfirmationMixin from '@/mixins/ConfirmationMixin';

  export default {
    components: {
      Tooltip,
    },

    mixins: [ConfirmationMixin],

    props: {
      /**
       * Input's 'checked' attribute
       */
      modelValue: {
        required: false,
        default: false,
      },
      /**
       * Input's 'name' attribute
       */
      name: {
        type: String,
      },
      /**
       * Disabled state
       */
      disabled: {
        type: Boolean,
        default: false
      },
      /**
       * Control size
       */
      size: {
        type: String,
        default: 'large',
        validator: function (value) {
          return ['extra-large', 'large', 'small'].indexOf(value) !== -1;
        },
      },
      /**
       * Control label
       */
      inputLabel: {
        type: String,
        required: false,
      },
      /**
       * Disable reason
       */
      disableReason: {
        type: String,
        required: false,
      },

      /**
       * Pass confirm action config object if user should confirm toggle switching to "on" position
       */
      confirmToggleOn: {
        type: Object,
        required: false,
        default: undefined,
      },

      /**
       * Pass confirm action config object if user should confirm toggle switching to "off" position
       */
      confirmToggleOff: {
        type: Object,
        required: false,
        default: undefined,
      },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        active: false,
      };
    },
    computed: {
      sizeClass() {
        switch (this.size) {
          case 'extra-large':
            return 'switch--size-xl';
          case 'large':
            return 'switch--size-l';
          case 'small':
            return 'switch--size-s';
          default:
            return 'switch--size-l';
        }
      },
    },
    methods: {
      async toggle(e) {
        const valueToSet = e.target.checked;

        const confirmationConfig = valueToSet ? this.confirmToggleOn : this.confirmToggleOff;

        if (confirmationConfig && !await this.requestConfirmation(confirmationConfig)) {
          e.target.checked = !valueToSet;
          return;
        }

        this.active = valueToSet;

        /**
         * Emitted when the control changes its value
         */
        this.$emit('update:modelValue', this.active);
      },
      parseBoolean(value) {
        return ["1", "true", true].includes(value);
      },
    },
    watch: {
      modelValue: {
        handler: function() {
          this.active = this.parseBoolean(this.modelValue);
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped>

.switch {
  @apply inline-flex items-center cursor-pointer mb-0 tracking-wider font-normal font-frank;
  font-size: 0.8125rem;
  color: #000000;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.switch__lever {
  @apply inline-block relative border align-middle;

  &:after {
    content: '';
    @apply block absolute ease-in-out duration-200;
    transition-property: left;
    left: 4px;
    top: 3px;
    background: #000000;
  }
}

.switch--size-xl {
  .switch__lever {
    @apply w-20 h-10;
    background: #EAEAEA;
    border-color: #E5E5E5;

    &:after {
      width: 30px;
      height: 30px;
      top: 4px;
    }
  }
}

.switch--size-l {
  .switch__lever {
    @apply h-8 w-16;
    background: #EAEAEA;
    border-color: #E5E5E5;

    &:after {
      @apply w-6 h-6;
    }
  }
}

.switch--size-s {
  .switch__lever {
    @apply h-6 bg-white border-gray-300;
    width: 2.75rem;

    &:after {
      @apply  w-4 h-4;
    }
  }
}

.switch:not(.switch--disabled) .switch__lever:hover {
  border-color: #999999;
}

.switch--active {
  @apply text-active-500;

  &.switch--size-s .switch__lever:after {
    left: 22px;
  }

  &.switch--size-l .switch__lever:after {
    left: 34px;
  }

  &.switch--size-xl .switch__lever:after {
    left: 44px;
  }

  .switch__lever:after {
    @apply bg-active-500;
  }

  .switch__lever:hover:after {
      @apply bg-active-600;
  }
}

.switch--disabled {
  cursor: not-allowed;
  color: #b2b2b2;

  &.switch--size-s .switch__lever {
    @apply border-gray-200;
  }

  .switch__lever:after,
  .switch__lever:hover:after {
    @apply bg-gray-300;
  }
}

</style>

<template>
    <QxtIconButton
        id="search-action"
        icon="search"
        label="Search"
        size="3xl"
        :disabled="!searchAllowed"
        @click="changeSearchVisibility(true)"
    />
    <QxtGlobalSearch
        v-show="searchAllowed"
        v-model="searchQuery"
        :show="searchVisibility"
        modal-for="search-action"
        :loading="loading"
        @close="changeSearchVisibility(false)"
    >
        <template
            v-if="searchResults.length"
            #default
        >
            <QxtSearchCategory
                v-for="{ title, data } of searchResults"
                :key="title"
                :title="title"
            >
                <QxtSearchResultItem
                    v-for="item in data"
                    :key="item.title"
                    :show-favorite="!!item.showFavorite"
                    :favorite="!!item.isFavorite"
                    :icon="item.icon"
                    :type="item.type"
                    :title="item.title"
                    :detail="item.detail"
                    :query="searchQuery"
                    @click="handleSearchResultClick(item)"
                >
                    <template
                        v-if="item.avatar || item.name"
                        #avatar
                    >
                        <QxtAvatar
                            size="md"
                            :src="item.avatar"
                            :name="item.name"
                        />
                    </template>
                </QxtSearchResultItem>
            </QxtSearchCategory>
        </template>
    </QxtGlobalSearch>
</template>

<script setup>
import { QxtGlobalSearch, QxtSearchCategory, QxtSearchResultItem, QxtAvatar, QxtIconButton } from '@asmartment/qui-base';

import { ref, shallowRef, computed, watch, onMounted } from 'vue';
import { debounce } from 'lodash-es';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useAlertStore } from '@/stores/alert';
import search from '@/search';
import { SECTIONS, SECTION_TITLES, SECTION_TYPES } from '@/search/sections';
import SearchDataProvider from '@/api/search';

const router = useRouter();
const store = useStore();
const { notifyError } = useAlertStore();

const loading = ref(false);
const searchVisibility = ref(false);
const searchCategories = shallowRef([]);
const searchQuery = ref('');
const searchResults = shallowRef([]);

const searchAllowed = computed(() => !!store.getters['auth/customerId'] && !!store.getters['auth/community']);

watch(
    () => searchQuery.value,
    debounce(query => {
        searchResults.value = [];
        if (query) {
            fetchSearchResults(query);
        }
    }, 500)
);

function changeSearchVisibility(value = !searchVisibility.value) {
    searchVisibility.value = value;
    if (value) {
        searchQuery.value = '';
        searchResults.value = [];
    }
}

function handleSearchResultClick({ route, routeParams }) {
    changeSearchVisibility(false);
    router.push({
        name: route,
        params: routeParams,
    });
}

async function fetchSearchResults(query) {
    loading.value = true;
    try {
        const results = await search(query);
        searchResults.value = parseSearchResults(results);
    } catch (error) {
        notifyError(error);
    } finally {
        loading.value = false;
    }
}

function parseSearchResults(results) {
    return searchCategories.value
        .map(({ entity_name }) => {
            switch (entity_name) {
                case SECTIONS.TASKS:
                    return {
                        title: SECTION_TITLES[SECTIONS.TASKS],
                        data: results[entity_name].map(item => ({
                            ...item,
                            detail: item.product,
                            isFavorite: false,
                            showFavorite: true,
                            type: SECTION_TYPES[SECTIONS.TASKS],
                        })),
                    };
                case SECTIONS.PEOPLE:
                    return {
                        title: SECTION_TITLES[SECTIONS.PEOPLE],
                        data: results[entity_name].map(item => ({
                            ...item,
                            name: item.title,
                            icon: 'user',
                        })),
                    };
                case SECTIONS.LOCATIONS:
                    return {
                        title: SECTION_TITLES[SECTIONS.LOCATIONS],
                        data: results[entity_name].map(item => ({
                            ...item,
                            icon: 'building-2',
                            type: SECTION_TYPES[SECTIONS.LOCATIONS],
                        })),
                    };
                case SECTIONS.MISCELLANEOUS:
                    return {
                        title: SECTION_TITLES[SECTIONS.MISCELLANEOUS],
                        data: results[entity_name].map(item => ({
                            ...item,
                            icon: 'garage',
                            type: SECTION_TYPES[SECTIONS.MISCELLANEOUS],
                        })),
                    };
                case SECTIONS.VENDORS:
                    return {
                        title: SECTION_TITLES[SECTIONS.VENDORS],
                        data: results[entity_name].map(item => ({
                            ...item,
                            type: SECTION_TYPES[SECTIONS.VENDORS],
                        })),
                    };
                default:
                    return {};
            }
        })
        .filter(({ data }) => data.length);
}

async function fetchSearchSettings() {
    try {
        const { entity = [] } = await SearchDataProvider.adapter.get('searchSettingsConfig', {
            size: 999,
        });
        searchCategories.value = entity.filter(item => item.is_active).sort((a, b) => a.order_no - b.order_no);
    } catch (error) {
        notifyError(error);
    }
}

onMounted(() => {
    fetchSearchSettings();
});
</script>

<template>
    <div>
        <button
            v-if="editable"
            class="btn-secondary btn-xs mb-8"
            :disabled="$boolAttr(!hasNonDefaultValues)"
            @click="resetStatuses"
        >
            <Tooltip v-if="hasNonDefaultValues">
                <template #label>
                    <Close2Icon class="w-2 h-2 mr-2" />
                    reset to default statuses all categories
                </template>

                <template #default>
                    <DefaultCategoriesList
                        :categories="defaultCategories"
                        labels-mode
                    />
                </template>
            </Tooltip>

            <template v-else>
                <Close2Icon class="w-2 h-2 mr-2" />
                reset to default statuses all categories
            </template>
        </button>

        <template
            v-for="category in categories"
            :key="category.code"
        >
            <div
                v-for="status in category.statuses"
                :key="status.code"
            >
                <div class="flex items-center mb-3">
                    <div class="font-frank font-bold text-black text-sm lowercase mr-1">
                        {{ category.name }}. {{ status.name }}
                    </div>
                    <tooltip
                        icon="info"
                        class="text-graphite-800"
                    >
                        <div class="flex flex-col">
                            <div class="mb-1">
                                {{ category.description }}
                            </div>
                            <div class="mb-1">
                                {{ status.description }}
                            </div>
                        </div>
                    </tooltip>
                </div>

                <div
                    v-if="editable"
                    class="flex items-center justify-between mb-4"
                >
                    <div class="flex justify-start">
                        <button
                            v-if="editable"
                            class="btn-secondary btn-xs"
                            :disabled="$boolAttr(isStatusDefault(status, category.code))"
                            @click="resetStatuses(category.code, status.code)"
                        >
                            <Tooltip v-if="!isStatusDefault(status, category.code)">
                                <template #label>
                                    <Close2Icon class="w-2 h-2 mr-2" />
                                    reset to default statuses
                                </template>

                                <template #default>
                                    <DefaultStatusesList
                                        :statuses="getDefaultStatus(status.code, category.code).labels"
                                        labels-mode
                                    />
                                </template>
                            </Tooltip>

                            <template v-else>
                                <Close2Icon class="w-2 h-2 mr-2" />
                                reset to default statuses
                            </template>
                        </button>

                        <button
                            v-if="canBeUndone(category, status)"
                            class="btn-secondary btn-xs ml-4"
                            @click="undoCategoryReset(category, status)"
                        >
                            undo
                        </button>
                    </div>

                    <button
                        v-if="status.extendable"
                        class="btn-secondary flex items-center mr-1"
                        :disabled="$boolAttr(showAddNewForm[status.code])"
                        @click="addNewLabel(status.code)"
                    >
                        <plus-circle-icon class="w-4 h-4 mr-1" />
                        add new status
                    </button>
                </div>

                <LabelsList
                    v-model:labels="status.labels"
                    :editable="editable"
                    :show-add-form="showAddNewForm[status.code]"
                    @cancelAddNew="cancelAddNewLabel(status.code)"
                    @submitNewLabel="values => handleNewLabelSubmit(category.code, status.code, values)"
                />
            </div>
        </template>

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import Close2Icon from '@/components/ui/icons/Close2Icon';
import Tooltip from '@/components/ui/Tooltip';
import Loader from '@/components/ui/Loader';
import { useAlertStore } from '@/stores/alert';
import LabelsList from '@/components/community_settings/unit_statuses/LabelsList';
import PlusCircleIcon from '@/components/ui/icons/PlusCircleIcon';
import StatusCompareMixin from '@/components/community_settings/unit_statuses/StatusCompareMixin';
import DefaultCategoriesList from '@/components/community_settings/unit_statuses/DefaultCategoriesList';
import DefaultStatusesList from '@/components/community_settings/unit_statuses/DefaultStatusesList';

export default {
    components: { DefaultStatusesList, DefaultCategoriesList, PlusCircleIcon, LabelsList, Loader, Tooltip, Close2Icon },

    mixins: [StatusCompareMixin],

    props: {
        editable: {
            type: Boolean,
            default: true,
        },

        defaultCategories: {
            type: Array,
            required: true,
        },
    },

    setup() {
        const { notifySuccess, notifyError } = useAlertStore();
        return { notifySuccess, notifyError };
    },

    data() {
        return {
            loading: false,
            categories: [],
            showAddNewForm: {},

            prevCategories: [],
        };
    },

    mounted() {
        this.loading = true;

        this.$cbrDataProvider
            .getCategories('unitStatuses', { part: 'PHYSICAL_STATUS' })
            .then(response => {
                this.categories = response;
            })
            .catch(error => {
                this.notifyError(error);
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        resetStatuses(categoryCode = undefined, statusCode = undefined) {
            this.loading = true;

            this.$cbrDataProvider
                .reset('unitStatuses', {
                    data: {
                        part: 'PHYSICAL_STATUS',
                        statusCode,
                    },
                })
                .then(response => {
                    this.prevCategories = [...this.categories];

                    this.categories = response;

                    const category = this.categories.find(({ code }) => code === categoryCode);
                    if (category) {
                        const statusName = category.statuses.find(({ code }) => code === statusCode).name;
                        this.notifySuccess(`${category.name}. ${statusName} statuses  reset to default`);
                    } else {
                        this.notifySuccess(`statuses in all categories reset to default`);
                    }
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        addNewLabel(statusCode) {
            this.showAddNewForm[statusCode] = true;
        },

        cancelAddNewLabel(statusCode) {
            this.showAddNewForm[statusCode] = false;
        },

        handleNewLabelSubmit(categoryCode, statusCode, values) {
            this.loading = true;

            this.$cbrDataProvider
                .create('unitStatusLabels', {
                    data: {
                        categoryCode,
                        statusCode,
                        ...values,
                    },
                })
                .then(response => {
                    this.categories
                        .find(({ code }) => code === categoryCode)
                        .statuses.find(({ code }) => code === statusCode)
                        .labels.unshift(response);
                    this.cancelAddNewLabel(statusCode);
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        canBeUndone(category, status) {
            const prevCategory = this.prevCategories.find(({ code }) => code === category.code);

            if (!prevCategory) return false;

            const prevStatus = prevCategory.statuses.find(({ code }) => code === status.code);

            if (!prevStatus) return false;

            if (status.labels.length !== prevStatus.labels.length) return true;

            for (const label of status.labels) {
                const prevLabel = prevStatus.labels.find(({ id }) => id === label.id);

                if (prevLabel && (label.name !== prevLabel.name || label.description !== prevLabel.description)) {
                    return true;
                }
            }

            return false;
        },

        undoCategoryReset(category, status) {
            const prevStatus = this.prevCategories
                .find(({ code }) => code === category.code)
                .statuses.find(({ code }) => code === status.code);

            const promises = [];

            for (const prevLabel of prevStatus.labels) {
                const label = status.labels.find(({ id }) => id === prevLabel.id);
                if (label) {
                    if (prevLabel.name !== label.name || prevLabel.description !== label.description) {
                        promises.push(
                            this.$cbrDataProvider
                                .update('unitStatusLabels', {
                                    id: label.id,
                                    data: {
                                        name: prevLabel.name,
                                        description: prevLabel.description,
                                    },
                                })
                                .then(updatedLabel => {
                                    // console.log(updatedLabel);
                                    status.labels[status.labels.findIndex(({ id }) => id === updatedLabel.id)] = updatedLabel;
                                })
                        );
                    }
                } else {
                    promises.push(
                        this.$cbrDataProvider
                            .create('unitStatusLabels', {
                                data: {
                                    categoryCode: category.code,
                                    statusCode: status.code,
                                    name: prevLabel.name,
                                    description: prevLabel.description,
                                },
                            })
                            .then(response => {
                                status.labels.unshift(response);
                            })
                    );
                }
            }

            for (const labelToRemove of status.labels.filter(label => !prevStatus.labels.find(({ id }) => id === label.id))) {
                promises.push(
                    this.$cbrDataProvider.delete('unitStatusLabels', { id: labelToRemove.id }).then(() => {
                        const categoryIndex = this.categories.findIndex(({ code }) => code === category.code);
                        const statusIndex = this.categories[categoryIndex].statuses.findIndex(({ code }) => code === status.code);

                        this.categories[categoryIndex].statuses[statusIndex].labels = this.categories[categoryIndex].statuses[
                            statusIndex
                        ].labels.filter(({ id }) => id !== labelToRemove.id);
                    })
                );
            }

            this.loading = true;
            Promise.all(promises)
                .then(() => {
                    this.prevCategories = [];
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

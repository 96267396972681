<template>
    <li class="category-menu__item" :class="$attrs['class']">
        <CategoryMenuItemButton v-bind="$filterBoolAttrs(inputAttrs)">
            <template #default>
                <slot />
            </template>

            <template #additional-content>
                <slot name="additional-content" />
            </template>
        </CategoryMenuItemButton>
    </li>
</template>

<script>
import { omit } from 'lodash-es';
import CategoryMenuItemButton from '@/components/ui/category-menu/CategoryMenuItemButton';

export default {
    components: {
        CategoryMenuItemButton,
    },

    inheritAttrs: false,

    computed: {
        inputAttrs() {
            return omit(this.$attrs, 'class');
        },
    },
}
</script>

<style scoped>
.category-menu__item {
    list-style: none;
}
</style>